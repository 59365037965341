.nl-search-group {
  border-radius: 8px;
  padding: 12px 24px;
  position: relative;
  background-color: #fff;
  border: 1px solid var(--#{$prefix}border-color);

  // elements
  .nl-search-element {
    gap: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    & > div {
      position: relative;

      &::after {
        @include media-breakpoint-up(md) {
          content: "";
          top: 50%;
          width: 1px;
          right: -7px;
          height: 50%;
          position: absolute;
          transform: translateY(-50%);
          background-color: $gray-700;
        }
      }

      &:nth-last-child(1) {
        &::after {
          @include media-breakpoint-up(md) {
            display: none;
          }
        }
      }
    }

    .form-select { 
      --#{$prefix}border-width: 0px;
      --#{$prefix}border-radius: 0px;
      
      font-size: 14px;
      background-position: right 0px center;

      &:focus { 
        box-shadow: none;
      }

    }
  }

  .nl-search-action {
    flex-shrink: 0;
  }

  .nl-search-field {
    gap: 8px;
    display: flex;
    align-items: center;

    .icon {
      opacity: 0.4;
    }

    input {
      border: 0px;
      &:focus,
      &:active {
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.#{$prefix}footer {

  --#{$prefix}footer-bg-color: #{$white};

  --#{$prefix}footer-info-title-color: var(--#{$prefix}heading-color);
  --#{$prefix}footer-info-text-color: var(--#{$prefix}body-color);
  
  --#{$prefix}footer-brand-info-text-color: var(--#{$prefix}body-color);
  --#{$prefix}footer-brand-info-text-size: var(--#{$prefix}body-font-size);

  --#{$prefix}footer-copyright-text-size: var(--#{$prefix}fs-7);
  --#{$prefix}footer-copyright-text-color: var(--#{$prefix}dark);


  background-color: var(--#{$prefix}footer-bg-color);

  &-menu {
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(md) {
      gap: 48px;
      flex-wrap: nowrap;
    }

    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }

    @include media-breakpoint-up(xl) {
      gap: 90px;
    }

    li {
      a {
        color: $dark;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.14;
        text-align: right;
        text-transform: uppercase;
        transition: all 0.3s linear;

        &:hover {
          color: var(--#{$prefix}primary);
        }
      }
    }
  }

  &-social {
    gap: 0.75rem;
    display: flex;
    align-items: center;

    li {
      .nl-media {
        transform: scale(1);
        transition: all 0.1s linear;

        &:hover {
          transform: scale(0.9);
        }
      }
    }
  }

  &-brand-info { 
    p { 
      color: var(--#{$prefix}footer-brand-info-text-color);
      font-size: var(--#{$prefix}footer-brand-info-text-size);
    }
  }

  &-info {

    h1,h2,h3,h4,h5,h6 {
      color: var(--#{$prefix}footer-info-title-color);
    }

    ul {
      li {
        a {
          transition: all 0.1s linear;
          color: var(--#{$prefix}footer-info-text-color);

          &:hover {
            color: var(--#{$prefix}primary) !important;
          }
        }
      }
    }
  }

  &-copyright {
    gap: 6px;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(sm) {
      gap: 16px;
    }

    li {
      position: relative;
      &::after {
        content: "";
        top: 50%;
        width: 1px;
        right: -4px;
        position: absolute;
        height: calc(100% - 12px);
        transform: translateY(-50%);
        background-color: var(--#{$prefix}border-color);

        @include media-breakpoint-up(sm) {
          right: -8px;
        }
      }

      &:nth-last-child(1) {
        &::after {
          display: none;
        }
      }

      a {
        white-space: nowrap;
        color: var(--#{$prefix}footer-copyright-text-color);
        font-size: var(--#{$prefix}footer-copyright-text-size);

        &:hover {
          color: var(--#{$prefix}primary);
        }
    }
    }
  }

  &-landing {
    &-copyright {
      text-align: center;
      background-color: #122544;
      span {
        color: #fff;
        font-size: 0.875rem;
        line-height: 1.375rem;
      }
    }
  }

  
  &-bottom {


    .#{$prefix}footer-content { 
      p { 
        color: var(--#{$prefix}footer-copyright-text-color);
        font-size: var(--#{$prefix}footer-copyright-text-size);
      }
    }
  }
}

.layout { 

  &-9,
  &-15,
  &-17,
  &-20,
  &-21 { 
    .#{$prefix}footer { 
      --#{$prefix}border-color: #2B292C;
      --#{$prefix}footer-bg-color: #{$gray-900};
     
      --#{$prefix}footer-brand-info-text-color: #{$white};
      
      --#{$prefix}footer-brand-info-text-color: #{$white};
      --#{$prefix}footer-info-text-color: #{$white};
      --#{$prefix}footer-copyright-text-color: #{$white};
      --#{$prefix}footer-info-title-color: var(--#{$prefix}white);
    
    }
  }

  &-15 { 
    .#{$prefix}footer {    
      --#{$prefix}heading-color: var(--#{$prefix}white);
    }
  }

  &-17 { 
    .#{$prefix}footer { 
      --#{$prefix}border-color: rgba(229, 231, 235, 0.12);

      --#{$prefix}footer-bg-color: #{$blue-700};
    }
  }

  &-22 { 
    .#{$prefix}footer { 
      --#{$prefix}footer-info-text-color: #{$dark};
      --#{$prefix}footer-brand-info-text-color: #{$dark};
    }
  }

}

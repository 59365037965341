
.#{$prefix}section {
  --#{$prefix}section-space: 30px 0px;
  --#{$prefix}section-head-space: 30px;

  @include media-breakpoint-up(md) {
    --#{$prefix}section-head-space: 60px;
  }

  @include media-breakpoint-up(lg) {
    --#{$prefix}section-space: 60px 0px;
  }

  &[class*="bg-dark"],
  &[class*="bg-gray-900"] {
    --#{$prefix}border-color: #2B292C;
  }

  &[class*="bg-"] {
    --#{$prefix}section-space: 60px 0px;

    @include media-breakpoint-up(lg) {
      --#{$prefix}section-space: 120px 0px;
    }

  }

  &[data-bg-image] {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  --#{$prefix}subtitle-line-height: 16px;
  --#{$prefix}subtitle-letter-spacing: null;
  --#{$prefix}subtitle-text-transform: uppercase;
  --#{$prefix}subtitle-padding: null;
  --#{$prefix}subtitle-bg-color: nulll;
  --#{$prefix}subtitle-border-radius: null;
  --#{$prefix}subtitle-color: var(--#{$prefix}primary);
  --#{$prefix}subtitle-font-size: var(--#{$prefix}fs-8);
  --#{$prefix}subtitle-font-weight: #{$font-weight-semibold};

  --#{$prefix}title-color: #{$dark};
  --#{$prefix}title-line-height: 1.5;
  --#{$prefix}title-letter-spacing: null;
  --#{$prefix}title-text-transform: capitalize;
  --#{$prefix}title-font-size: var(--#{$prefix}fs-3);
  --#{$prefix}title-font-weight: #{$font-weight-bold};

  @include media-breakpoint-up(lg) {
    --#{$prefix}title-font-size: var(--#{$prefix}fs-2);
  }

  z-index: 1;
  position: relative;
  padding: var(--#{$prefix}section-space);

  &-subtitle {
    margin-bottom: 12px;
    display: inline-block;
    color: var(--#{$prefix}subtitle-color);
    padding: var(--#{$prefix}subtitle-padding);
    font-size: var(--#{$prefix}subtitle-font-size);
    font-weight: var(--#{$prefix}subtitle-font-weight);
    line-height: var(--#{$prefix}subtitle-line-height);
    background: var(--#{$prefix}subtitle-bg-color);
    border-radius: var(--#{$prefix}subtitle-border-radius);
    letter-spacing: var(--#{$prefix}subtitle-letter-spacing);
    text-transform: var(--#{$prefix}subtitle-text-transform);

    @include media-breakpoint-up(lg) {
      margin-bottom: 0px;
    }

  }

  &-title {
    color: var(--#{$prefix}title-color);
    letter-spacing: var(--#{$prefix}title-letter-spacing);
    text-transform: var(--#{$prefix}title-text-transform);
    font-size: var(--#{$prefix}title-font-size) !important;
    line-height: var(--#{$prefix}title-line-height) !important;
    font-weight: var(--#{$prefix}title-font-weight) !important;
  }

  &-head {
    position: relative;
    padding-bottom: var(--#{$prefix}section-head-space);

    p:last-child { 
        margin-bottom: 0;
      }
  }

  // reset spacing top/bottom on banner/hero
  &.nl-banner {
    padding: 0;
  }
}

.layout {
  &-2,
  &-4,
  &-5,
  &-15,
  &-16,
  &-18,
  &-22,
  &-23 {
    .#{$prefix}section {
      @include media-breakpoint-up(lg) {
        --#{$prefix}title-line-height: 1.33;
        --#{$prefix}title-font-size: var(--#{$prefix}fs-1);
      }
    }
  }

  &-15,
  &-23 {
    .#{$prefix}section {
      --#{$prefix}title-letter-spacing: -0.25;
    }
  }

  &-1 {
    .#{$prefix}section {
      --#{$prefix}subtitle-letter-spacing: 0.04em;
      --#{$prefix}subtitle-text-transform: uppercase;
    }
  }

  &-3 {
    .#{$prefix}section {
      --#{$prefix}subtitle-padding: 8px 16px;
      --#{$prefix}subtitle-color: var(--#{$prefix}white);
      --#{$prefix}subtitle-bg-color: var(--#{$prefix}primary);
      --#{$prefix}subtitle-border-radius: #{$border-radius-pill};
    }
  }

  &-8 {
    .#{$prefix}section {
      --#{$prefix}subtitle-padding: 0.25rem 0.75rem;
      --#{$prefix}subtitle-bg-color: var(--#{$prefix}primary-soft);
      --#{$prefix}subtitle-border-radius: var(--#{$prefix}border-radius-pill);
    }
  }

  &-9 {
    .#{$prefix}section {
      --#{$prefix}title-color: #{$white};
      --#{$prefix}title-line-height: 1.2;
      --#{$prefix}title-text-transform: uppercase;
    }
  }

  &-10 {
    .#{$prefix}section {
      --#{$prefix}subtitle-padding: 0.5rem 1rem;
      --#{$prefix}subtitle-bg-color: var(--#{$prefix}primary-soft);
      --#{$prefix}subtitle-border-radius: var(--#{$prefix}border-radius-pill);
    }
  }

  &-15 { 
    .#{$prefix}section { 
      --#{$prefix}title-text-transform: null;
    }
  }


  &-17 {
    .#{$prefix}section {
      @include media-breakpoint-up(lg) {
        --#{$prefix}title-font-size: 6rem;
        --#{$prefix}title-line-height: 7.25rem;
      }
    }
  }

  &-18 {
    .#{$prefix}section {
      --#{$prefix}subtitle-padding: 0.5rem 1.5rem;
      --#{$prefix}subtitle-color: var(--#{$prefix}white);
      --#{$prefix}subtitle-bg-color: var(--#{$prefix}primary);
      --#{$prefix}subtitle-border-radius: var(--#{$prefix}border-radius-pill);

      --#{$prefix}title-text-transform: null;
    }
  }

  &-21 {
    .#{$prefix}section {

      --#{$prefix}title-font-size: var(--#{$prefix}fs-4);
      
      @include media-breakpoint-up(lg) {
        --#{$prefix}title-line-height: 1.625;
        --#{$prefix}title-font-size: var(--#{$prefix}fs-3);
      }
    }
  }

  &-23 {
    .#{$prefix}section {
      --#{$prefix}subtitle-padding: 8px 1.5rem;
      --#{$prefix}subtitle-color: var(--#{$prefix}white);
      --#{$prefix}subtitle-border-radius: var(--#{$prefix}border-radius-pill);
      --#{$prefix}subtitle-bg-color: linear-gradient(276.37deg, #5CC1FF 5.91%, #6754F3 50.33%, #C4B9FF 94.75%);
    }
  }
}

%shape-bg {
  content: "";
  z-index: -1;
  opacity: 0.6;
  user-select: none;
  position: absolute;
  pointer-events: none;
  background-size: cover;
  background-repeat: no-repeat;
}

.#{$prefix}section {

  // business solution - Features 2 page
  &-tools {
    z-index: 1;
    overflow: hidden;
    position: relative;
  }

  &-integrate {
    .nl-block-head-content {
      padding: 60px 0px;
    }
  }

  &-teams {
    .#{$prefix}section {
      &-content {
        .swiper {
          &-slide {
            transform: scale(0.8);
            transition: all 0.3s linear;

            img {
              transition: all 0.3s ease-in;
              filter: grayscale(100%) blur(1px);
              -webkit-filter: grayscale(100%) blur(1px);
            }

            &-active {
              transform: scale(1);

              img {
                filter: grayscale(0%) blur(0px);
                -webkit-filter: grayscale(0%) blur(0px);
              }
            }
          }

          &-pagination {
            margin: 0px auto;
            transform: none;
            position: static;
            padding-top: 30px;

            @include media-breakpoint-up(lg) {
              padding-top: 60px;
            }

            &-bullet {
              transition: all 0.2s linear;
              &-active {
                width: 24px;
                border-radius: 6px;
              }
            }
          }
        }
      }
    }
  }

  // Layouts

  &-info-about {
    .nl-frame {
      &-children {
        display: none;
        @include media-breakpoint-up(md) {
          bottom: 0;
          left: -30px;
          display: block;
        }

        @include media-breakpoint-up(lg) {
          bottom: -30px;
        }
      }
    }
  }

  &-job-details {
    @include media-breakpoint-up(lg) {
      margin-top: -280px;
    }

    .#{$prefix}section {
      &-wrap {
        @include media-breakpoint-down(md) {
          padding-top: 120px;
        }
      }

      &-content {
        @include media-breakpoint-up(lg) {
          border-radius: 16px;
          background-color: $white;
          border: 1px solid var(--#{$prefix}border-color);
        }
      }
    }

    .nl-entry {
      @include media-breakpoint-up(lg) {
        border-right: 1px solid var(--#{$prefix}border-color);
      }
    }
  }

  // Shops
  &-products {
    .#{$prefix}section {
      &-content {
        &-sidebar {
          @include media-breakpoint-up(lg) {
            left: 0;
            top: 120px;
            position: sticky;
          }
        }
      }
    }
  }

  &-product-details {
    z-index: 1;
    position: relative;

    &::before {
      content: "";
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("#{$coreRoot}inside-pages/bg/product-details-bg.svg");
    }

    .nl-product {
      &-specification {
        gap: 14px;
        display: flex;
        flex-direction: column;
        &-content {
          gap: 50px;
          display: flex;
          max-width: 300px;
          align-items: center;
          justify-content: space-between;

          .color-select {
            gap: 10px;
            display: flex;
            align-items: center;
            .form-check-input {
              width: 18px;
              height: 18px;

              &:checked {
                box-shadow: none;
                background: none;
                transition: all 0.2s ease-in-out;
              }

              &.almond {
                border-color: transparent;
                background-color: #eed5c1;

                &:checked {
                  border-color: #faf3ed;
                }
              }
              &.steel-blue {
                border-color: transparent;
                background-color: #64728f;

                &:checked {
                  border-color: #d1d5de;
                }
              }

              &.electric-blue {
                border-color: transparent;
                background-color: #13d5ff;

                &:checked {
                  border-color: #b9f3ff;
                }
              }

              &.dark-olive {
                border-color: transparent;
                background-color: #49430b;

                &:checked {
                  border-color: #c9c7b6;
                }
              }
            }
          }
        }
      }
    }

    .nl-social {
      & > ul {
        gap: 14px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        li {
          position: relative;

          &::after {
            content: "";
            width: 1px;
            height: 50%;
            top: 50%;
            right: -7px;
            position: absolute;
            transform: translateY(-50%);
            background-color: #64728f;
          }

          &:nth-last-child(1) {
            &::after {
              display: none;
            }
          }
        }
      }
    }

    .nl-nav-tabs {
      .nav-tabs {
        gap: 12px;
        border-bottom: 1px solid $border-color;

        @include media-breakpoint-up(md) {
          gap: 20px;
        }

        .nav-link {
          border: 0;
          padding: 0px;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.14;
          border-radius: 0px;
          color: #64728f;
          padding-bottom: 12px;
          text-transform: uppercase;
          transition: all 0.3s linear;
          border-bottom: 2px solid transparent;

          @include media-breakpoint-up(md) {
            padding-bottom: 24px;
          }

          &.active {
            color: #0a1425;
            border-color: $primary;
          }
        }
      }
    }

    .swiper {
      &.product-slider-lg {
        .swiper-slide {
          overflow: hidden;
          position: relative;
          border-radius: 10px;
        }
      }
      &.product-slider-sm {
        .swiper-slide {
          overflow: hidden;
          position: relative;
          border-radius: 4px;
          transition: all 0.3s linear;
          border: 2px solid transparent;

          &-thumb-active {
            border-color: var(--#{$prefix}primary);
          }
        }
      }

      &-button-next,
      &-button-prev {
        opacity: 0.6;
        transition: all 0.3s linear;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

}


.layout {

  &-20 {

    .#{$prefix}section {
      &-case {
        background-size: cover;
        background-position: center;

        &::after {
          content: "";
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          position: absolute;
          background: linear-gradient(90deg, #2f5f70 10.12%, rgba(47, 95, 112, 0) 100%);
        }
      }

      &-complishment {
        .#{$prefix}section {
          &-content {
            @include media-breakpoint-up(md) {
              padding: 30px;
              border-radius: 24px;
              background-color: $white;
              box-shadow:
                4px 4px 36px 0px #c0c0c029,
                -4px -4px 36px 0px #c0c0c01f;
            }
    
            @include media-breakpoint-up(lg) {
              padding: 60px;
            }
    
            @include media-breakpoint-up(xl) {
              margin-top: -180px;
            }
          }
        }
      }

    }


  }

  // App Page
  &-5 {
    
    .#{$prefix}section {

      &-support {
        &::after {
          @extend %shape-bg;
          right: 0;
          top: -60px;
          width: 36.9%;
          height: calc(100% + 60px);
          border-top-left-radius: 240px;
          background-color: #e3f1ef;
        }

        .nl-mask {
          &.shape {
            &::after,
            &::before {
              content: "";
              z-index: -1;
              opacity: 0.6;
              user-select: none;
              position: absolute;
              pointer-events: none;
              background-size: cover;
              background-repeat: no-repeat;
            }

            &::before {
              @include media-breakpoint-up(lg) {
                top: 0;
                left: 0;
                width: 266px;
                height: 449px;
                background-size: contain;
                background-image: url("#{$coreRoot}inside-pages/objects/object-2.png");
              }
            }

            &::after {
              @include media-breakpoint-up(lg) {
                right: 0;
                bottom: 0;
                width: 330px;
                height: 330px;
                background-size: contain;
                background-image: url("#{$coreRoot}inside-pages/objects/object-1.png");
              }
            }
          }
        }
      }

      &-cta {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          position: absolute;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("#{$coreRoot}inside-pages/bg/live-chat-cta-bg.png");
        }
      }
    }
  }

  &-6 {
    .#{$prefix}section {
      &-content {
        .nl-mask {
          z-index: 1;

          &.shape {
            &::before,
            &::after {
              @extend %shape-bg;
              top: 0;
              z-index: 1;
              height: 100%;
              width: 558px;
              background-position: center;
            }

            &::before {
              left: 0;
              background-image: url("#{$coreRoot}shape/y.png");
            }

            &::after {
              right: 0;
              background-image: url("#{$coreRoot}shape/z.png");
            }
          }
        }
      }
    }
  }

  &-10 {
    .#{$prefix}section {
      &-player {
        @include media-breakpoint-down(md) {
          padding-bottom: 30px;
        }

        .nl-frame {
          z-index: 1;

          &-children {
            @include media-breakpoint-down(md) {
              display: none;
            }
            &-one {
              top: -50px;
              left: -45px;
            }

            &-two {
              right: 0px;
              z-index: -1;
              bottom: -45px;
              @include media-breakpoint-up(md) {
                right: -50px;
              }
            }
          }
        }

        .nl-video {
          height: auto;
          margin-top: 60px;

          @include media-breakpoint-up(lg) {
            margin-top: -130px;
          }

          &-img {
            width: 100%;
          }

          &-counter {
            margin-top: 30px;

            @include media-breakpoint-up(md) {
              z-index: 6;
              width: 80%;
              margin: 0px auto;
              margin-top: -80px;
              position: relative;
            }

            @include media-breakpoint-up(lg) {
              width: 95%;
            }

            @include media-breakpoint-up(xl) {
              width: 928px;
            }
          }
        }
      }
    }

    // swiper
    .nl-testimonial-card-s3 .nl-testimonial-icon .icon {
      background: linear-gradient(180deg, $teal-600 0%, $info 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &-13 {
  
    .#{$prefix}section {


      @include media-breakpoint-up(lg) { 
        --#{$prefix}title-font-size: var(--#{$prefix}fs-1);
      }

      &-career-details {
        .#{$prefix}section {
          &-blog-details {
            ul {
              li {
                p {
                  &:nth-child(1) {
                    width: 30%;
                  }
                  &:last-child(1) {
                    width: 70%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  &-22 {
    .#{$prefix}section {
      &-testimonials {
        @include media-breakpoint-up(lg) {
          z-index: 2;
        }

        .nl-button {
          @include media-breakpoint-up(lg) {
            left: 0;
            top: 50px;
            position: absolute;
          }

          @include media-breakpoint-up(xl) {
            top: 48px;
          }
        }
      }

      &-revolution {
        padding: 60px 0px;

        &::before {
          content: "";
          z-index: -1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          background-size: cover;
          background-repeat: no-repeat;
          background-image: url("#{$coreRoot}inside-pages/bg/green-tech-bg-1.svg");
        }

        @include media-breakpoint-up(lg) {
          margin-top: -198px;
          padding-top: 280px;
          padding-bottom: 120px;
        }

        @include media-breakpoint-up(xl) { 
          margin-top: -172px;
        }

        @media (min-width: 1400px) {
          margin-top: -244px;
          padding-top: 370px;
          padding-bottom: 220px;
        }
      }

      &-power-info {
        @include media-breakpoint-down(xl) {
          padding: 60px 0px;
        }

        @include media-breakpoint-up(xl) {
          margin-top: -60px;
          padding-bottom: 120px;
        }

        @media (min-width: 1400px) {
          margin-top: -100px;
        }

        .nl-frame {
          &-info {
            @include media-breakpoint-up(xl) {
              width: 650px;
              height: auto;
              position: absolute;
            }

            p {
              @include media-breakpoint-down(md) {
                font-size: 16px !important;
              }
            }
          }

          &-one {
            .nl-frame {
              &-info {
                @include media-breakpoint-up(xl) {
                  right: 0;
                  top: 180px;
                }
              }
            }
          }

          &-two {
            @include media-breakpoint-up(xl) {
              text-align: right;
              margin-top: 172px;
            }

            .nl-frame {
              &-info {
                left: 0;
                top: -170px;
                text-align: left;
              }
            }
          }
        }
      }

      &-community {
        .nl-frame {
          & > img {
            @include media-breakpoint-down(xl) {
              width: 100%;
            }
          }

          &-info {
            @include media-breakpoint-up(xl) {
              top: 50%;
              right: 0;
              width: 645px;
              position: absolute;
              transform: translateY(-50%);
            }

            p {
              @include media-breakpoint-down(md) {
                font-size: 16px !important;
              }
            }
          }
        }
      }

      &-stories {
        &::before {
          content: "";
          z-index: -1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          background-size: cover;
          background-repeat: no-repeat;
          background-image: url("#{$coreRoot}inside-pages/bg/green-tech-cta-bg.png");
        }

        .nl-frame {
          .nl-video-btn {
            @include media-breakpoint-up(lg) {
              top: auto;
              left: auto;
              right: 30px;
              bottom: 30px;
              transform: none;
            }
          }
        }
      }
    }
  }

  // SASS Page

  &-23 {
    .#{$prefix}section {
      &-feature {
        .swiper {
          &-button-group {
            position: static;
          }

          &-button-prev,
          &-button-next {
            position: static;
          }
        }
      }

      &-podcast-cta {
        .#{$prefix}section {
          &-content {
            z-index: 1;
            position: relative;

            .nl-mask {
              &.blur {

                &::after,
                &::before {
                  content: "";
                  width: auto;
                  height: auto;
                  position: absolute;
                  background-size: contain;
                  background-repeat: no-repeat;
                }

                &::before {
                  left: 0px;
                  top: -260px;
                  width: 977px;
                  height: 923px;
                  background-image: url("#{$coreRoot}/blur/cta-podcast-blur-1.png");
                }

                &::after {
                  bottom: 0px;
                  right: -32px;
                  width: 572px;
                  height: 540px;
                  background-image: url("#{$coreRoot}/blur/cta-podcast-blur-2.png");
                }
              }
            }

            p {
              @include media-breakpoint-down(md) {
                font-size: 16px !important;
              }
            }
          }
        }
      }
    }
  }
}

// Pagee Inner Section
.#{$prefix}section {
  
  &-help-desk-feature {
    .nl-frame {
      img {
        overflow: hidden;
        border-radius: 1rem;
      }
    }

    .layout-one {
      .nl-frame {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: 712.37px;
          height: 603.83px;
        }

        @include media-breakpoint-up(md) {
          margin: auto;
        }

        @include media-breakpoint-up(xl) {
        }

        &-children {
          position: relative;

          @include media-breakpoint-up(xl) {
            position: absolute;
          }

          &-one {
            @include media-breakpoint-up(xl) {
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }

            img {
              @include media-breakpoint-down(md) {
                width: 100%;
              }
            }
          }

          &-two,
          &-three {
            @include media-breakpoint-down(md) {
              display: none;
            }

            @include media-breakpoint-down(xl) {
              position: absolute;
            }
          }

          &-two {
            @include media-breakpoint-up(md) {
              top: 0px;
              right: 70px;
            }
          }

          &-three {
            @include media-breakpoint-up(md) {
              width: 252px;
              right: 70px;
              bottom: 48px;
            }

            @media (min-width: 1400px) {
              right: 0;
              bottom: 0;
              width: auto;
            }
          }
        }
      }
    }
  }
}

//  responsive breakpoints
@include media-breakpoint-up(lg) {
  .#{$prefix}section {
    &-blog {
      &-sidebar {
        top: 120px;
        left: 0px;
        position: sticky;
      }
    }

    &-terms {
      .nl-entry {
        &-sidebar {
          top: 120px;
          left: 0px;
          position: sticky;
        }
      }
    }

    &-integrate {
      .nl-block-head-content {
        padding: 120px 0px;
      }

      &::before {
        display: none;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .#{$prefix}section {
    &-integrate {
      .nl-block-head-content {
        padding: 245px 0px;
      }
    }
  }
}

.nl-contact {
    &-box {
        &.with-transparent {
            background-color: $white;
        }
    }
    &-content {
        padding: $contact-box-content-padding;
    }
}

.form-label{
    --#{$prefix}form-label-color: #{$form-label-color};
    --#{$prefix}form-label-font-size: #{$form-label-font-size};
    --#{$prefix}form-label-font-weight: #{$form-label-font-weight};

    color: var(--#{$prefix}form-label-color);
    font-size: var(--#{$prefix}form-label-font-size);
    font-weight: var(--#{$prefix}form-label-font-weight);
}





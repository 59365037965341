a {
    transition:
        color 0.2s,
        background-color 0.2s,
        border 0.2s,
        box-shadow 0.2s;
}

img {
    max-width: 100%;
}

ol,
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

canvas {
    width: 100%;
    max-width: 100%;
}

div,
.card {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    p {
        &:nth-last-child(1) {
            margin-bottom: 0px;
        }
    }
}

// Typography
h1,
.h1 {
    line-height: 1.3;
}

h2,
.h2 {
    line-height: $line-height-sm;
}

h3,
.h3 {
    line-height: 1.6;
    letter-spacing: -0.02em;
}
h4,
.h4 {
    line-height: 1.7;
    letter-spacing: -0.015em;
}
h5,
.h5 {
    line-height: 1.6;
    letter-spacing: -0.01em;
}
h6,
.h6 {
    line-height: 1.6; // 58
}

.display-6 {
    line-height: 1.32;
    letter-spacing: -0.025em;
}

// Layouts
body {


    &.layout-1,
    &.layout-16 {
        --#{$prefix}primary: #{$blue};
        --#{$prefix}primary-soft: #{$blue-100};
        --#{$prefix}primary-rgb: #{to-rgb($blue)};
        --#{$prefix}primary-focus-box-shadow: #{0 0 $focus-ring-blur $focus-ring-width rgba($blue, 0.25)};

        --#{$prefix}body-line-height: 1.6;
        --#{$prefix}link-hover-color: #{shade-color($blue, 15%)};
        --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);

        @include media-breakpoint-up(lg) { 
            --#{$prefix}body-font-size: 1.25rem;        
        }

        // utility classes
        .bg-primary-soft {
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

        .text-bg-primary-soft {
            --#{$prefix}text-color: var(--#{$prefix}primary);
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

    }

    &.layout-3,
    &.layout-17 {
        --#{$prefix}primary: #{$indigo-700};
        --#{$prefix}primary-soft: #{$purple-200};
        --#{$prefix}primary-rgb: #{to-rgb($indigo-700)};
        --#{$prefix}primary-focus-box-shadow: #{0 0 $focus-ring-blur $focus-ring-width rgba($indigo-700, 0.25)};
        --#{$prefix}link-hover-color: #{shade-color($indigo-700, 15%)};
        --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);

        --#{$prefix}border-radius: 0.75rem;
        

        // utility classes
        .bg-primary-soft {
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

        .text-bg-primary-soft {
            --#{$prefix}text-color: var(--#{$prefix}primary);
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }



    }

    &.layout-4,
    &.layout-12 {
        --#{$prefix}primary: #{$blue-600};
        --#{$prefix}primary-soft: #{$gray-100};
        --#{$prefix}primary-rgb: #{to-rgb($blue-600)};
        --#{$prefix}primary-focus-box-shadow: #{0 0 $focus-ring-blur $focus-ring-width rgba($blue-600, 0.25)};

        --#{$prefix}link-hover-color: #{shade-color($blue-600, 15%)};
        --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);

        @include media-breakpoint-down(md) { 
            --#{$prefix}body-font-size: var(--#{$prefix}fs-7);
        }


        // utility classes
        .bg-primary-soft {
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

        .text-bg-primary-soft {
            --#{$prefix}text-color: var(--#{$prefix}primary);
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

      

    }

    &.layout-6,
    &.layout-14 {
        --#{$prefix}primary: #{$indigo-600};
        --#{$prefix}primary-soft: #{$gray-100};
        --#{$prefix}primary-rgb: #{to-rgb($indigo-600)};
        --#{$prefix}primary-focus-box-shadow: #{0 0 $focus-ring-blur $focus-ring-width rgba($indigo-600, 0.25)};

        --#{$prefix}link-hover-color: #{shade-color($indigo-600, 15%)};
        --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);

        // utility classes
        .bg-primary-soft {
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

        .text-bg-primary-soft {
            --#{$prefix}text-color: var(--#{$prefix}primary);
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

       


    }

    &.layout-7,
    &.layout-15,
    &.layout-21 {
        --#{$prefix}primary: #{$indigo-500};
        --#{$prefix}primary-soft: #{$gray-100};
        --#{$prefix}primary-rgb: #{to-rgb($indigo-500)};
        --#{$prefix}primary-focus-box-shadow: #{0 0 $focus-ring-blur $focus-ring-width rgba($indigo-500, 0.25)};
        --#{$prefix}link-hover-color: #{shade-color($indigo-500, 15%)};
        --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);

        // utility classes

        .bg-primary-soft {
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

        .text-bg-primary-soft {
            --#{$prefix}text-color: var(--#{$prefix}primary);
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }



    }

    &.layout-9,
    &.layout-19,
    &.layout-23 {
        --#{$prefix}primary: #{$purple-500};
        --#{$prefix}primary-soft: #{$purple-100};
        --#{$prefix}primary-rgb: #{to-rgb($purple-500)};
        --#{$prefix}primary-focus-box-shadow: #{0 0 $focus-ring-blur $focus-ring-width rgba($purple-500, 0.25)};
        --#{$prefix}link-hover-color: #{shade-color($purple-500, 15%)};
        --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);

        // utility classes
        .bg-primary-soft {
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

        .text-bg-primary-soft {
            --#{$prefix}text-color: var(--#{$prefix}primary);
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }



    }

    &.layout-10,
    &.layout-20 {
        --#{$prefix}primary: #{$cyan-600};
        --#{$prefix}primary-soft: #{$gray-100};
        --#{$prefix}primary-rgb: #{to-rgb($cyan-600)};
        --#{$prefix}primary-focus-box-shadow: #{0 0 $focus-ring-blur $focus-ring-width rgba($cyan-600, 0.25)};
        --#{$prefix}link-hover-color: #{shade-color($cyan-600, 15%)};
        --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);

        // utility classes
        .bg-primary-soft {
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

        .text-bg-primary-soft {
            --#{$prefix}text-color: var(--#{$prefix}primary);
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

        
    }

    &.layout-2 {
        --#{$prefix}primary: #{$red};
        --#{$prefix}primary-soft: #{$red-100};
        --#{$prefix}primary-rgb: #{to-rgb($red)};
        --#{$prefix}primary-focus-box-shadow: #{0 0 $focus-ring-blur $focus-ring-width rgba($red, 0.25)};
        --#{$prefix}link-hover-color: #{shade-color($red, 15%)};
        --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);


        --#{$prefix}border-radius: #{$border-radius-lg};


        // typography 
        

        h1,
        .h1 {
            @include media-breakpoint-up(lg) {
                line-height: 1.6;
                font-size: 3.5rem;
                letter-spacing: -0.02em;
            }
        }

        h2,
        .h2 {
            @include media-breakpoint-up(lg) {
                font-size: 3rem;
                line-height: 1.4;
                letter-spacing: -0.02em;
            }
        }

        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            line-height: 1.6;
        }

        h3,
        .h3 {
            letter-spacing: -0.02em;
        }
        h4,
        .h4 {
            letter-spacing: -0.015rem;
        }
        h5,
        .h5 {
            letter-spacing: -0.01em;
        }
        h6,
        .h6 {
            letter-spacing: -0.01em;
        }


        // utility classes
        .bg-primary-soft {
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

        .text-bg-primary-soft {
            --#{$prefix}text-color: var(--#{$prefix}primary);
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }



    }


    &.layout-4 {

        // typography 
        h1,
        .h1 {
            @include media-breakpoint-up(lg) {
                line-height: 1.6;
                font-size: 3.5rem;
                letter-spacing: -0.02em;
            }
        }

        h2,
        .h2 {
            @include media-breakpoint-up(lg) {
                font-size: 3rem;
                line-height: 1.4;
                letter-spacing: -0.02em;
             }
        }
        h3,
        .h3 {
            @include media-breakpoint-up(lg) { 
                font-size: 2.25rem;
                letter-spacing: -0.01em;
            }
        }

        h5,
        .h5 {
            line-height: 1.6;
            letter-spacing: -0.01em;
        }
        h6,
        .h6 {
            letter-spacing: -0.01em;
        }

        // utility classes
        .nl-section {
            --#{$prefix}subtitle-font-weight: #{$font-weight-bold};
        }

    }

    &.layout-5 {
        --#{$prefix}primary: #{$teal-700};
        --#{$prefix}primary-soft: #{$teal-100};
        --#{$prefix}primary-rgb: #{to-rgb($teal-700)};
        --#{$prefix}primary-focus-box-shadow: #{0 0 $focus-ring-blur $focus-ring-width rgba($teal-700, 0.25)};
        --#{$prefix}link-hover-color: #{shade-color($teal-700, 15%)};
        --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);


        // typography 
        h1,
        .h1 {
            letter-spacing: -0.02em;
        }

        h2,
        .h2 {
            line-height: 1.4;
            letter-spacing: -0.02em;
        }
        h3,
        .h3 {
            line-height: 1.4;
        }
        h4,
        .h4 {
            line-height: 1.68;
        }

        // utility classes
        .bg-primary-soft {
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

        .text-bg-primary-soft {
            --#{$prefix}text-color: var(--#{$prefix}primary);
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }



    }

    &.layout-7 { 

        @include media-breakpoint-down(md) { 
            --#{$prefix}body-font-size: var(--#{$prefix}fs-7);
        }

    }

    &.layout-8 {
        --#{$prefix}primary: #{$green-500};
        --#{$prefix}primary-soft: #{$green-100};
        --#{$prefix}primary-rgb: #{to-rgb($green-500)};
        --#{$prefix}primary-focus-box-shadow: #{0 0 $focus-ring-blur $focus-ring-width rgba($green-500, 0.25)};
        --#{$prefix}link-hover-color: #{shade-color($green-500, 15%)};
        --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);

        // utility classes
        .bg-primary-soft {
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

        .text-bg-primary-soft {
            --#{$prefix}text-color: var(--#{$prefix}primary);
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }


    }

    &.layout-9 {
        --#{$prefix}border-radius: 0px;
        --#{$prefix}border-color: #2b292c;
        --#{$prefix}body-bg: var(--#{$prefix}gray-900);
        --#{$prefix}body-color: var(--#{$prefix}gray-600);
        --#{$prefix}heading-color: var(--#{$prefix}white);

        z-index: 1;
        position: relative;

        &::after {
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            position: absolute;
            background-size: contain;
            background-repeat: no-repeat;
            background-color: var(--#{$prefix}body-bg);
            background-image: url("#{$coreRoot}mask/mask-body-1.png");
        }
        @include media-breakpoint-up(lg) {
            .display-6 {
                font-size: 4rem;
                line-height: 4.5rem;
                letter-spacing: -0.025em !important;
            }
        }

        // typography
        h1,
        .h1 {
            line-height: 1.16;
        }

        h4,
        .h4 {
            line-height: 1.33;
        }

        h5,
        .h5 {
            line-height: 1.4;
            letter-spacing: -0.015em;
        }

        // utility class
        .text-dark {
            --#{$prefix}dark-rgb: #{to-rgb($white)};
        }


        .form-control {
            --#{$prefix}input-color: var(--#{$prefix}body-color);
        }
    }

    &.layout-10 {

        // utility class 
        .nl-section { 
            --#{$prefix}subtitle-color: var(--#{$prefix}white);            
            --#{$prefix}subtitle-bg-color: var(--#{$prefix}danger);
        }   

    }

    &.layout-11 {
        --#{$prefix}primary: #{$red-500};
        --#{$prefix}primary-soft: #{$red-100};
        --#{$prefix}primary-rgb: #{to-rgb($red-500)};
        --#{$prefix}primary-focus-box-shadow: #{0 0 $focus-ring-blur $focus-ring-width rgba($red-500, 0.25)};
        --#{$prefix}link-hover-color: #{shade-color($red-500, 15%)};
        --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);

        // utility classes

        .bg-primary-soft {
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

        .text-bg-primary-soft {
            --#{$prefix}text-color: var(--#{$prefix}primary);
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

    }

    &.layout-13 {
        --#{$prefix}primary: #{$teal-600};
        --#{$prefix}primary-soft: #{$gray-100};
        --#{$prefix}primary-rgb: #{to-rgb($teal-600)};
        --#{$prefix}primary-focus-box-shadow: #{0 0 $focus-ring-blur $focus-ring-width rgba($teal-600, 0.25)};
        --#{$prefix}link-hover-color: #{shade-color($teal-600, 15%)};
        --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);


        @include media-breakpoint-up(lg) { 
            --#{$prefix}body-font-size: 1.125rem;
          }
          

        // typography

        h2,
        .h2 {
            font-size: 3rem;
            line-height: 1.33;
            margin-bottom: 12px;
        }


        // utility classes
        .bg-primary-soft {
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

        .text-bg-primary-soft {
            --#{$prefix}text-color: var(--#{$prefix}primary);
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }
    }

    &.layout-14 {

        --#{$prefix}body-line-height: 1.6;
        --#{$prefix}body-color: #{$gray-700};

        @include media-breakpoint-up(lg) {
            --#{$prefix}body-font-size: 1.25rem;
        }

        // utility classes        
        .bg-dark { 
            --#{$prefix}dark-rgb: 35, 35, 64;
        }


    }

    &.layout-15 {

        @include media-breakpoint-up(lg) { 
            --#{$prefix}body-font-size: 18px;
        }

        // utility class
        .nl-section { 
            --#{$prefix}title-letter-spacing: 0;
        }
    }

    &.layout-16,
    &.layout-17 {    
        
        @include media-breakpoint-up(lg) { 
            --#{$prefix}body-font-size: 1.125rem;
          }     

        // typography 
        .display-1 {
             @include media-breakpoint-up(lg) { 
                font-size: 6rem;
             }
        }
    }


    &.layout-17,
    &.layout-19 { 
        --#{$prefix}body-color: #{$dark};
    }


    &.layout-16 { 
        --#{$prefix}gray-200-rgb: 250, 249, 244; 
    }

    &.layout-17 { 
        --#{$prefix}body-color: #{$dark};
        --#{$prefix}indigo-100-rgb: 157, 144, 241; 
        --#{$prefix}yellow-400-rgb: 255, 222, 112;
    }
    
    
    &.layout-18 {
        --#{$prefix}primary: #{$purple-400};
        --#{$prefix}primary-soft: #{$purple-100};
        --#{$prefix}primary-rgb: #{to-rgb($purple-400)};
        --#{$prefix}primary-focus-box-shadow: #{0 0 $focus-ring-blur $focus-ring-width rgba($purple-400, 0.25)};
        --#{$prefix}link-hover-color: #{shade-color($purple-400, 15%)};
        --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);

        // utility classes
        .bg-primary-soft {
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

        .text-bg-primary-soft {
            --#{$prefix}text-color: var(--#{$prefix}primary);
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }


        


    }


    &.layout-19,
    &.layout-20 { 
        
        --#{$prefix}border-radius: 1rem;

        // component
        .card { 
            --#{$prefix}card-border-width: 0px;
        }
    }

    &.layout-20 {
        --#{$prefix}primary-soft: #{$cyan-100};
    }

    &.layout-21 {
        --#{$prefix}body-font-size: 1rem;
    }

    &.layout-22 {
        --#{$prefix}primary: #{$green-600};
        --#{$prefix}primary-soft: #{$green-100};
        --#{$prefix}primary-rgb: #{to-rgb($green-600)};
        --#{$prefix}primary-focus-box-shadow: #{0 0 $focus-ring-blur $focus-ring-width rgba($green-600, 0.25)};        
        --#{$prefix}link-hover-color: #{shade-color($green-600, 15%)};
        --#{$prefix}link-hover-color-rgb: var(--#{$prefix}primary-rgb);

        --#{$prefix}border-radius: 0px;


        // utility classes
        .bg-primary-soft {
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }

        .text-bg-primary-soft {
            --#{$prefix}text-color: var(--#{$prefix}primary);
            --#{$prefix}bg-soft-color: var(--#{$prefix}primary-soft);
        }


    }

    &.layout-23 { 
        --#{$prefix}body-font-size: 1.125rem;        
        // --#{$prefix}primary: ;        
    }

}

// pricing Toggle active color
.nl-pricing {
    .form-check {
        &-input {
            &:checked {
                background-color: currentColor;
            }
        }
    }
}



.swiper {
  --swiper-theme-color: var(--#{$prefix}primary);

  --#{$prefix}swiper-pagination-size: 0.75rem;

  --#{$prefix}swiper-pagination-bg-color: var(--#{$prefix}primary);
  --#{$prefix}swiper-pagination-active-bg-color: var(--#{$prefix}primary);

  --#{$prefix}swiper-nav-button-size: 3.375rem;
  --#{$prefix}swiper-nav-button-color: #{$dark};

  --#{$prefix}swiper-nav-bg-color: var(--#{$prefix}white);
  --#{$prefix}swiper-nav-hover-bg-color: var(--#{$prefix}primary);

  --#{$prefix}swiper-nav-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;

  // swiper block and elements

  // navigation
  &-button {
    // &-group{}

    &-next,
    &-prev {
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      transition: all 0.2s linear;
      width: var(--#{$prefix}swiper-nav-button-size);
      height: var(--#{$prefix}swiper-nav-button-size);
      color: var(--#{$prefix}swiper-nav-button-color);
      box-shadow: var(--#{$prefix}swiper-nav-box-shadow);
      background-color: var(--#{$prefix}swiper-nav-bg-color);

      &::after {
        line-height: 1.6;
        font-size: 1.5rem;
        font-family: $nio-font-family;
      }

      &:hover {
        color: #fff;
        background-color: var(--#{$prefix}swiper-nav-hover-bg-color);
      }
    }

    &-prev {
      &::after {
        content: $ni-back-ios;
      }
    }

    &-next {
      &::after {
        content: $ni-forward-ios;
      }
    }
  }

  // pagination
  &-pagination {
    &-bullet {
      width: var(--#{$prefix}swiper-pagination-size);
      height: var(--#{$prefix}swiper-pagination-size);
      background-color: var(--#{$prefix}swiper-pagination-bg-color);

      &-active {
        background-color: var(--#{$prefix}swiper-pagination-active-bg-color);
      }
    }
  }

  // previous code start
  &-wrapper {
    &.has-pagination {
      padding-bottom: 60px;
      &-and-navigation {
        padding-bottom: 100px;
        + .swiper-pagination-fraction,
        + .swiper-pagination-custom,
        + .swiper-horizontal > .swiper-pagination-bullets,
        + .swiper-pagination-bullets.swiper-pagination-horizontal {
          bottom: 100px;
        }
      }
    }
    &.has-navigation {
      padding-bottom: 40px;
    }
  }

  &-button {

    &-group {
      &-s1 {
        display: flex;
        align-items: center;
        .swiper-button-prev,
        .swiper-button-next {
          position: inherit;
          top: auto;
          margin: 8px;
        }
        .swiper-button-prev {
          left: auto;
        }
        .swiper-button-next {
          right: auto;
        }
      }
      &-s2 {
        .swiper-button-disabled {
          display: none;
        }
        .swiper-button-next,
        .swiper-rtl .swiper-button-prev {
          right: -8px;
        }
      }
      &.has-bg {
        .swiper-button-prev,
        .swiper-button-next {
          box-shadow: none;
          color: var(--#{$prefix}dark);
          background-color: var(--#{$prefix}gray);
          &:hover {
            color: var(--#{$prefix}white);
            background-color: var(--#{$prefix}primary);
          }
        }
      }

      &.arrow-down { 

        @include media-breakpoint-down(lg) {
          gap: .75rem;
           display: flex;
           align-items: center;
           justify-content: center;
        }

        .swiper-button-prev,
        .swiper-button-next { 
          position: static;

          @media (min-width: 992px) {
            top: calc(100% - 50px); 
            position: absolute;
          }

          @media (min-width: 1400px)  { 
              top: calc(100% - 70px); 
          }

        }

        .swiper-button-prev { 
          left: auto;
          right: 80px;
        }
      }
    }
  }

  &-pagination {
    &-s1 {
      bottom: 0 !important;
      .swiper-pagination-bullet {
        width: $swiper-pagination-s1-bullet-width;
        height: $swiper-pagination-s1-bullet-height;
        background-color: $swiper-pagination-s1-bullet-inactive-color;
        border-radius: $swiper-pagination-s1-bullet-border-radius;
        opacity: 1;
        &:hover,
        &-active {
          background-color: $swiper-pagination-s1-bullet-active-color;
        }
      }
    }

    &-4 {
      .swiper-pagination-bullet {
        background-color: #fff;
        &-active {
          background-color: $primary;
        }
      }
    }
  }
}

.nl-swiper {
  &-s2 {
    .swiper {
      // components

      &-button-prev {
        left: 0;
      }

      &-button-next {
        right: 0;
      }

      &-slide {
        &:not(.swiper-slide-active) {
          opacity: 0.7;
          pointer-events: none;
        }
      }

      &-pagination {
        &-bullet {
          opacity: 1;
          background-color: #faf9ff;
          &-active {
            background-color: $primary;
          }
        }
      }
    }

    &.overflow-visible {
      .swiper-button {
        &-prev {
          transform: translateX(-50%);
        }
        &-next {
          transform: translateX(50%);
        }
      }
    }

    // variants
    &.blog-slider {
      .swiper {
        &-pagination {
          text-align: center;

          @include media-breakpoint-up(xl) {
            text-align: end;
          }

          &-bullet {
            width: $swiper-pagination-s1-bullet-width;
            height: $swiper-pagination-s1-bullet-height;
            background-color: $swiper-pagination-s1-bullet-inactive-color;
            border-radius: $swiper-pagination-s1-bullet-border-radius;
            opacity: 1;
            &:hover,
            &-active {
              background-color: $swiper-pagination-s1-bullet-active-color;
            }
          }
        }
      }
    }
  }

  &-s3 {
    // swiper component
    .swiper {
      &-button-prev,
      &-button-next {
        &.swiper-button-disabled {
          opacity: 0;
        }
      }
    }
  }

  &-s4 {
    .swiper {
      // components
      &-button {
        &-prev,
        &-next {
          transform: translateY(-50%);
        }

        &-prev {
          left: 12px;
        }

        &-next {
          right: 12px;
        }
      }

      &-slide {
        transition: all 0.2s linear;
      }
    }

    &.overflow-visible {
      .swiper-button {
        &-prev {
          transform: translateX(-50%);
        }
        &-next {
          transform: translateX(50%);
        }
      }
    }
  }
}

// responsive devices
@include media-breakpoint-up(lg) {
  .nl-swiper {
    &-s1 {
      .swiper-slide {
        transition: 0.5s;
        transform: scale(0.6);
        &-active {
          transform: scale(1);
          transform-origin: center;
        }
        &-prev {
          transform-origin: right;
        }
        &-next {
          transform-origin: left;
        }
      }
    }

    &-s4 {
      .swiper {
        &-slide {
          transition: all 0.2s linear;
          &-active {
            padding-left: 100px;
          }
          &-next {
            padding-right: 100px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .swiper-button {
    &-group {
      &-s2 {
        .swiper-button-next,
        .swiper-rtl .swiper-button-prev {
          right: -60px;
        }
        .swiper-button-prev,
        .swiper-rtl .swiper-button-next {
          left: -60px;
        }
      }
    }
  }
}


  .swiper-rtl .swiper-button-prev:after { 
    content: "\ea6d";   
  }
  
  .swiper-rtl .swiper-button-next:after { 
    content: "\e958";   
  }



  .layout { 
    &-22 { 
      .swiper { 
        --#{$prefix}swiper-nav-button-size: 2.5rem;
      }   

      .swiper-button-next::after, .swiper-button-prev::after { 
        font-size: 1.125rem;
      }
    }
  }
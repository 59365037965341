.nl-list {
    &-meta {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        li {
            padding-right: $list-meta-item-gap-x;
            position: relative;
            a {
                color: var(--#{$prefix}dark);
            }
            &::after {
                top: 50%;
                right: 8px;
                width: 4px;
                height: 4px;
                content: "";
                position: absolute;
                border-radius: 50%;
                transform: translateY(-50%);
                background-color: var(--#{$prefix}dark);
            }
            &:last-child {
                padding-right: 0;
                &::after {
                    display: none;
                }
            }
        }
    }
    &-link {
        &.column {
            &-2 {
                column-count: 2;
            }
        }
        li {
            display: flex;
            padding: 8px 0px;
            align-items: center;
            a {
                display: block;
                line-height: 1.5;
                color: var(--#{$prefix}dark);
                &:hover {
                    color: var(--#{$prefix}primary);
                }
            }

            p {
                color: var(--#{$prefix}dark);
            }
        }
        
        &.horizontal {
            display: flex;
            flex-wrap: wrap;

            li {
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }

            &.separator {

                li {
                    position: relative;
                    padding-left: 0.75rem;
                    padding-right: 0.75rem;

                    &::after {
                        content: "";
                        top: 50%;
                        width: 5px;
                        right: -2px;
                        height: 1px;
                        position: absolute;
                        transform: translateY(-50%);
                        background-color: var(--#{$prefix}primary);
                    }
                    
                    &:last-child {
                        padding-right: 0;
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
        &.flush {
            > li {
                &:not(:last-child) {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    border-bottom: 1px solid $border-light;
                }
            }
        }
        &-page {
            li {
                font-weight: 600;
                a:not(:hover) {
                    color: var(--#{$prefix}dark);
                }
                &.active {
                    a {
                        color: $primary;
                    }
                }
            }
        }
        &-collapse {
            li {
                display: flex;
                flex-direction: column;
            }
            .nl-list-dot {
                padding-left: 1rem;
                font-weight: 500;
            }
            &-link {
                display: flex !important;
                align-items: center;
                justify-content: space-between;
                font-weight: 600;
                &[aria-expanded="true"] {
                    color: $primary !important;
                    .on {
                        display: none;
                    }
                    .off {
                        display: block;
                    }
                }
            }
        }
    }
    &-dot {
        &-item {
            position: relative;
            padding-left: $list-dot-item-gap-x;
            padding-bottom: $list-dot-item-gap-y;
            &::after {
                position: absolute;
                content: "";
                top: $list-dot-item-after-top;
                left: 0;
                width: $list-dot-item-after-width;
                height: $list-dot-item-after-height;
                background-color: $gray-500;
                border-radius: $list-dot-item-after-border-radius;
            }
        }
        &-sm {
            .nl-list-dot-item {
                padding-left: $list-dot-sm-item-gap-x;
                font-size: $list-dot-sm-item-font-size;
                &::after {
                    top: $list-dot-sm-item-after-top;
                    width: $list-dot-sm-item-after-width;
                    height: $list-dot-sm-item-after-height;
                }
            }
        }
    }
}

